<template>
  <form ref="form" :disabled="props.disabled" @submit.prevent="onSubmit">
    <slot />
  </form>
</template>

<script lang="ts" setup>
interface BaseFormProps {
  disabled?: boolean
}

const props = withDefaults(defineProps<BaseFormProps>(), { disabled: false })
const form = ref<HTMLFormElement | null>(null)
const emit = defineEmits(['submit'])

function onSubmit() {
  if (form.value?.reportValidity()) {
    emit('submit')
  }
}
</script>
